import * as giftCardProductApi from '../api/giftCardProductApi';
import { GiftCardProduct } from '../../../../types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export class GiftCardStore {
  protected readonly setProps: Function;
  protected loadingProduct: boolean;
  protected product: GiftCardProduct | null;
  protected instance: any;
  protected instanceId: any;
  protected currencyCode: string;
  protected locale: string;
  protected httpClient: IHttpClient;
  protected customVariantDisplay: null | string;

  constructor({ flowAPI, controllerConfig }: any) {
    this.setProps = controllerConfig.setProps;
    this.instance = controllerConfig.appParams.instance;
    this.instanceId = controllerConfig.appParams.instanceId;
    this.currencyCode = controllerConfig.wixCodeApi.site.currency;
    this.locale = controllerConfig.wixCodeApi.site.regionalSettings;
    this.loadingProduct = true;
    this.product = null;
    this.httpClient = flowAPI.httpClient;
    this.customVariantDisplay = null;
  }

  setCustomVariantDisplay(foramt: string) {
    this.customVariantDisplay = foramt;
    this.updateComponent();
  }

  updateComponent() {
    this.setProps({
      giftCardStore: this.toProps(),
    });
  }

  async init() {
    this.updateComponent();
    try {
      const product = await giftCardProductApi.getProduct(
        this.instance,
        this.httpClient,
      );

      if (!this.currencyCode) {
        this.currencyCode = await giftCardProductApi.getCurrencyCode(
          this.instance,
          this.httpClient,
        );
      }

      if (product) {
        this.product = product;
        this.loadingProduct = false;
        this.updateComponent();
      }
    } catch (error) {}
  }

  toProps() {
    return {
      loadingProduct: this.loadingProduct,
      product: this.product,
      locale: this.locale,
      currencyCode: this.currencyCode,
      customVariantDisplay: this.customVariantDisplay,
      setCustomVariantDisplay: this.setCustomVariantDisplay.bind(this),
    };
  }
}
