import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getProduct = async (instance: any, httpClient: IHttpClient) => {
  const getProductRequest = await httpClient.request<any>({
    url: 'https://www.wix.com/_serverless/rise-proxy/rise',
    method: 'post',
    headers: {
      Authorization: instance,
    },
    disableWixHeaders: true,
    data: {
      riseRequest: {
        url: 'https://wix.rise.ai/catalog/templates',
        method: 'get',
        data: {},
      },
    },
  });

  const gift_card_product = getProductRequest.data.gift_card_product;
  return gift_card_product;
};

export const getCurrencyCode = async (
  instance: any,
  httpClient: IHttpClient,
) => {
  const getCurrencyCodeRequest = await httpClient.request<string>({
    url: 'https://www.wix.com/_serverless/rise-proxy/wix',
    method: 'post',
    headers: {
      Authorization: instance,
    },
    disableWixHeaders: true,
    data: {
      wixRequest: {
        serviceRequest: {
          entity: 'SettingsReaderService',
          method: 'getCurrency',
          params: {},
        },
      },
    },
  });

  return getCurrencyCodeRequest.data;
};
