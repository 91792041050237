import _ from 'lodash';
import queryString from 'query-string';
import * as checkoutApi from '../api/checkoutApi';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GiftCardProduct, GiftCardCatalogOptions } from '../../../../types';
import { PurchaseOptions } from '../components/GiftCard/Widget/stores/checkout';

const parseFullName = (fullName: string) => {
  const [first, ...last] = fullName.split(' ').filter(Boolean);
  return {
    first,
    last: last.join(' '),
  };
};

export class CheckoutStore {
  protected readonly setProps: Function;
  protected flowAPI: ControllerFlowAPI;
  protected purchase_options_validation: {
    [key: string]: boolean;
  };
  protected show_errors: boolean;
  protected checkout: { id: string } | null;
  protected redirect_path: string | null;
  protected creatingCheckout: boolean;
  protected instance: any;
  protected instanceId: any;
  protected wixWindow: any;

  constructor({ flowAPI, controllerConfig }: any) {
    this.flowAPI = flowAPI;
    this.setProps = controllerConfig.setProps;
    this.instance = controllerConfig.appParams.instance;
    this.instanceId = controllerConfig.appParams.instanceId;
    this.wixWindow = controllerConfig.wixCodeApi.window;

    this.purchase_options_validation = {
      recipientEmail: false,
      quantity: true,
    };

    this.show_errors = false;
    this.checkout = null;
    this.redirect_path = null;
    this.creatingCheckout = false;
  }

  updateComponent() {
    this.setProps({
      checkoutStore: this.toProps(),
    });
  }

  async init() {
    this.updateComponent();
  }

  async buyGiftCard(
    product: GiftCardProduct,
    currencyCode: string,
    purchase_options: PurchaseOptions,
  ) {
    const { bi, environment } = this.flowAPI;
    const { isSSR, isMobile } = environment;
    const product_id = product.product_id;
    this.show_errors = true;
    this.updateComponent();

    if (purchase_options.isGift) {
      const validationValues = _.values(this.purchase_options_validation);
      const isValid = _.every(validationValues, Boolean);

      if (!isValid) {
        return;
      }
    } else {
      const isValid = this.purchase_options_validation.quantity;

      if (!isValid) {
        return;
      }
    }

    this.creatingCheckout = true;
    this.updateComponent();

    const {
      variantId,
      recipientEmail,
      recipientName,
      deliverAt,
      greetingMessage,
      quantity,
      isGift,
    } = purchase_options;

    const checkoutPurchaseOptions = {
      variantId,
      quantity,
    } as GiftCardCatalogOptions;

    if (isGift) {
      checkoutPurchaseOptions.giftingInfo = {
        recipientInfo: {
          firstName: parseFullName(recipientName).first,
          lastName: parseFullName(recipientName).last,
          email: recipientEmail,
        },
        greetingMessage,
      };

      if (deliverAt) {
        checkoutPurchaseOptions.giftingInfo.deliverAt = deliverAt;
      }
    }

    try {
      const checkout = await checkoutApi.createCheckout(
        this.instance,
        product_id,
        checkoutPurchaseOptions,
      );

      this.checkout = checkout;

      const lang = this.flowAPI.translations.config.language;
      const defaultLanguage = this.flowAPI.translations.config.defaultLanguage;
      const isPrimaryLanguage = lang === defaultLanguage;

      const checkoutUrlParams = {
        lang,
        isPrimaryLanguage,
        a11y: true,
        storeUrl: location.origin,
        plainTextisFastFlow: false,
        plainTextisPickupFlow: false,
        cashierPaymentId: '',
        origin: 'productPage',
        originType: 'buyNow',
        checkoutId: checkout.id,
      };

      const redirectQuery = queryString.stringify(
        {
          showMobileView: isMobile || null,
          appSectionParams: JSON.stringify(checkoutUrlParams),
        },
        {
          skipNull: true,
        },
      );
      this.redirect_path = `/checkout?${redirectQuery}`;

      const selectedVariant = _.find(
        product.variants,
        (variant) => variant.id === variantId,
      );

      if (!isSSR) {
        bi?.report({
          evid: 20,
          src: 125,
          endpoint: 'gc-client-sf',
          params: {
            productId: product_id,
            checkoutId: checkout.id,
            priceOption: selectedVariant?.price,
            currency: currencyCode,
            hasDeliveryDate: !!deliverAt,
            deliveryDate: deliverAt,
            editorMode: isMobile ? 'mobile' : 'desktop',
            quantity,
            type: 'buy now',
            redirectType: 'checkout',
          },
        });

        this.wixWindow.trackEvent('InitiateCheckout', {
          origin: 'Giftcard',
          contents: [
            {
              price: selectedVariant?.price,
              currency: currencyCode,
              quantity,
            },
          ],
        });
      }
    } catch (error) {}

    this.updateComponent();
  }

  setPurchaseOptionsValidation(key: string, value: any) {
    _.set(this.purchase_options_validation, key, value);
    this.updateComponent();
  }

  toProps() {
    return {
      purchase_options_validation: this.purchase_options_validation,
      show_errors: this.show_errors,
      setPurchaseOptionsValidation:
        this.setPurchaseOptionsValidation.bind(this),
      checkout: this.checkout,
      redirect_path: this.redirect_path,
      creatingCheckout: this.creatingCheckout,
      buyGiftCard: this.buyGiftCard.bind(this),
    };
  }
}
