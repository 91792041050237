import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { GiftCardStore } from '../../stores/GiftCardStore';
import { CheckoutStore } from '../../stores/CheckoutStore';
import jwtDecode from 'jwt-decode';

const Controller: CreateControllerFn = async (controllerProps) => {
  const { flowAPI, controllerConfig } = controllerProps;
  const { metaSiteId } = jwtDecode(controllerConfig.appParams.instance) as {
    metaSiteId: String;
  };

  flowAPI?.bi?.updateDefaults({
    appDefId: controllerConfig.appParams.appDefinitionId,
    instanceId: controllerConfig.appParams.instanceId,
    msid: metaSiteId,
  });
  const giftCardStore = new GiftCardStore(controllerProps);
  const checkoutStore = new CheckoutStore(controllerProps);

  return {
    async pageReady() {
      await giftCardStore.init();
      await checkoutStore.init();
    },
    exports: () => ({
      setCustomVariantDisplay: (foramt: string) => {
        giftCardStore.setCustomVariantDisplay(foramt);
      },
    }),
  };
};

export default Controller;
