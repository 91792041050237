import { Checkout, ApiChannelType } from '@wix/ambassador-checkout/http';
import APP from '../../.application.json';
import { GiftCardCatalogOptions } from '../../../../types';

const BASE_URL = '/ecom';

const getHeaders = (instance: any) => ({
  Authorization: instance,
});

const checkoutService = Checkout(BASE_URL).CheckoutService();

export const createCheckout = async (
  instance: any,
  product_id: any,
  purchase_options: GiftCardCatalogOptions,
) => {
  const headers = getHeaders(instance);
  const { checkout } = await checkoutService(headers).createCheckout({
    lineItems: [
      {
        quantity: purchase_options.quantity,
        catalogReference: {
          appId: APP.appDefinitionId,
          catalogItemId: product_id,
          options: purchase_options,
        },
      },
    ],
    channelType: ApiChannelType.WEB,
    checkoutInfo: {},
  });
  return checkout as any;
};

export const getWixCheckoutURL = async (instance: any, checkoutId: any) => {
  const headers = getHeaders(instance);
  const checkoutURL = await checkoutService(headers).getWixCheckoutURL({
    id: checkoutId,
  });
  return checkoutURL as string;
};
